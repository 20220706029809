<template>
    <div class="stateSelect">
        <!-- .is-error.el-form-item adds a red border -->
        <div class="el-form-item mb-0" :class="{ 'is-error': error }">
            <el-select
                :size="size"
                clearable
                multiple
                filterable
                @change="handleChange"
                :value="value"
                value-key="id"
                :loading="isLoading"
                placeholder="Select States"
            >
                <el-option v-for="state in states" :label="state.name" :key="state.id" :value="state"> </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        error: {
            type: String,
        },

        size: {
            type: String,
            default: 'mini',
        },

        country: {
            type: Number,
            default: 1,
        },

        value: {
            required: false,
            type: Array,
        },
    },

    data() {
        return {
            isLoading: false,
            states: [],
        };
    },

    created() {
        this.fetchStates();
    },

    methods: {
        fetchStates() {
            this.isLoading = true;
            axios.get('/api/states?country_id=' + this.country).then(response => {
                this.isLoading = false;
                this.states = response.data.data;
            });
        },

        handleChange(val) {
            this.$emit('input', val);
        },
    },
};
</script>
