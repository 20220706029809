<template>
    <div class="form-group" :class="{ 'has-danger': errors.get('subject') }">
        <label for="subject">Subject *</label>
        <modal v-if="modalVisible">
            <div slot="body">
                <h4>Dynamic Fields</h4>
                <div v-html="fields.help"></div>
            </div>
            <div slot="footer">
                <button
                    class="btn btn-secondary mr-auto"
                    @click.prevent="modalVisible = false"
                >
                    Close
                </button>
            </div>
        </modal>
        <input v-model="subjectField" hidden name="subject" />
        <span @click="handleUpdate" @keydown.enter.prevent>
            <VueTrix
                :value="subjectField"
                class="subjectTrixField"
                v-if="!loading"
                ref="trixSubjectField"
                v-model="subjectField"
                @update="handleUpdate"
                inputId="subjectTrix"
                placeholder=""
            />
        </span>
        <span
            class="form-control-feedback"
            v-show="errors.has('subject')"
            v-text="errors.get('subject')"
        ></span>
    </div>
</template>
<script>
import modal from "../components/modal.vue";
import dynamicQuickpitchFields from "../components/dynamic-quickpitch-fields.json";
import Trix from "trix";
import VueTrix from "vue-trix";
import Errors from "../form/errors.js";

export default {
    props: {
        errors: {
            required: false,
            type: Object,
            default: () => new Errors(),
        },
        subject: {
            required: false,
            type: String,
        },
        dynamic: {
            required: false,
            default: true,
            type: Boolean,
        },
    },
    components: {
        modal,
        Trix,
        VueTrix,
    },
    data() {
        return {
            modalVisible: false,
            subjectField: "",
            loading: true,
            position: "",
            fields: [],
        };
    },
    watch: {
        subject(value) {
            this.subjectField = value;
        },
    },
    created() {
        this.fields = dynamicQuickpitchFields;
        this.subjectField = this.subject;
    },
    mounted() {
        this.loading = false;
        var vm = this;

        document.addEventListener("trix-initialize", function (event) {
            let input = document.getElementById("subjectTrix");
            let trixEditor = document.querySelector(
                "trix-editor[input=subjectTrix]"
            );
            if (event.target.inputElement == input) {
                trixEditor.addEventListener("paste", function (event) {
                    let paste = (
                        event.clipboardData || window.clipboardData
                    ).getData("text");
                    paste = paste.replace(/<\/?[^>]+(>|$)/g, "");
                    const selection = window.getSelection();
                    if (!selection.rangeCount) return false;
                    selection.deleteFromDocument();
                    selection
                        .getRangeAt(0)
                        .insertNode(document.createTextNode(paste));
                    event.preventDefault();
                });
                trixEditor.addEventListener("keydown", function (event) {
                    if (event.metaKey) {
                        trixEditor.editor.deactivateAttribute("bold");
                        trixEditor.editor.deactivateAttribute("italic");
                        trixEditor.editor.deactivateAttribute("underline");
                        trixEditor.editor.deactivateAttribute("strikethrough");
                    }
                });
                input.name = "subject";
            } else if (vm.dynamic) {
                let dynamicHelpButton =
                    dynamicQuickpitchFields["dynamic-help-button"];
                let dynamicSubject = dynamicQuickpitchFields["dynamic-subject"];

                trixEditor.toolbarElement
                    .querySelector(".trix-button-group--text-tools")
                    .insertAdjacentHTML("afterend", dynamicHelpButton);
                trixEditor.toolbarElement
                    .querySelector(".trix-button-group--text-tools")
                    .insertAdjacentHTML("beforeend", dynamicSubject);

                document
                    .getElementById("dynamicHelpButton")
                    .addEventListener("click", function (event) {
                        event.preventDefault();
                        vm.modalVisible = !vm.modalVisible;
                    });
            }
        });
    },
    methods: {
        handleUpdate(event) {
            this.position =
                this.$refs.trixSubjectField.$refs.trix.editor.getPosition();
            if (
                event.target &&
                event.target.attributes["data-trix-attribute"]
            ) {
                let trixAttribute =
                    event.target.attributes["data-trix-attribute"];
                let field = dynamicQuickpitchFields[trixAttribute.value];
                let attachment = new Trix.Attachment({ content: field });
                if (field) {
                    this.$refs.trixSubjectField.$refs.trix.editor.setSelectedRange(
                        this.position
                    );
                    this.$refs.trixSubjectField.$refs.trix.editor.insertAttachment(
                        attachment
                    );
                }
                if (trixAttribute.value == "booker") {
                    this.$refs.trixSubjectField.$refs.trix.editor.insertString(
                        ","
                    );
                }
            }
            this.$emit("input", this.subjectField);
        },
        showDynamicFieldsModal() {
            this.modalVisible = !this.modalVisible;
        },
    },
};
</script>

<style scoped>
.trix-content {
    overflow-y: hidden;
}
</style>
