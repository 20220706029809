<template>
    <span @click="handleUpdate">
        <VueTrix
            v-if="!loading"
            ref="trixPreview"
            v-model="trixBody"
            @update="handleUpdate"
            inputId="bodyTrix"
            placeholder=""
        />
    </span>
</template>

<script>
import Trix from 'trix';
import fields from './dynamic-quickpitch-fields.json';
import VueTrix from 'vue-trix';

export default {
    props: {
        body: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            loading: true,
            trixBody: '',
            position: '',
        };
    },
    components: {
        VueTrix,
    },
    created() {
        const setTrixBody = new Promise(resolve => {
            this.trixBody = this.body;
            this.loading = false;
            resolve(this.body);
        });
        setTrixBody.then(body => {
            this.$refs.trixPreview.$refs.trix.value = body;
        });
    },
    mounted() {
        var vm = this;

        var alerted = false;

        document.addEventListener('trix-initialize', function (event) {
            let input = document.getElementById('bodyTrix');
            if (event.target.inputElement == input) {
                var dynamicTextButton = fields['dynamic-dropdown'];
                input.name = 'body';

                let trixToolbar = vm.$refs.trixPreview.$refs.trix.toolbarElement;
                trixToolbar
                    .querySelector('.trix-button-group--text-tools')
                    .insertAdjacentHTML('afterbegin', dynamicTextButton);
            }
        });

        document.addEventListener('trix-attachment-add', function (event) {
            if (event.attachment.attachment.preloadingURL) {
                event.attachment.remove();

                if (alerted === false) {
                    alert(
                        'Image attachments are not supported. It is best to use simple text and links when initially reaching out to venues for booking consideration.'
                    );

                    alerted = true;
                }
            }
        });
    },
    methods: {
        handleUpdate(event) {
            this.position = this.$refs.trixPreview.$refs.trix.editor.getPosition();
            var vm = this;
            if (event.target && event.target.attributes['data-trix-attribute']) {
                let trixAttribute = event.target.attributes['data-trix-attribute'];
                let field = fields[trixAttribute.value];
                let attachment = new Trix.Attachment({ content: field });
                if (field) {
                    vm.$refs.trixPreview.$refs.trix.editor.setSelectedRange(vm.position);
                    vm.$refs.trixPreview.$refs.trix.editor.insertAttachment(attachment);
                }

                if (trixAttribute.value == 'booker') {
                    vm.$refs.trixPreview.$refs.trix.editor.insertString(',');
                }
            }
            this.$emit('input', this.trixBody);
        },
    },
};
</script>
